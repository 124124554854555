<template>
  <div class="tw-relative tw-w-full">
    <div
      class="tw-h-full tw-w-full tw-rounded tw-bg-white tw-shadow tw-transition-shadow tw-duration-100 tw-ease-out hover:tw-shadow-hover"
    >
      <RatioContainer :x-aspect="3" :y-aspect="2">
        <a
          class="tw-relative tw-block tw-h-full tw-w-full"
          :href="post.link"
          target="_blank"
          @click="handlePostClick"
        >
          <img
            class="tw-h-full tw-w-full tw-object-cover tw-object-center"
            :src="post.picture_url"
          />
        </a>
      </RatioContainer>
      <div class="tw-flex tw-w-full tw-flex-col tw-p-5">
        <VText :cfg="titleConfig" color="black" class="clamp tw-mb-2">
          {{ post.title }}
        </VText>
        <Sm400Gray class="clamp">{{ post.body }}.</Sm400Gray>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import RatioContainer from '~/components/shared/ratioContainer.vue'
import { Sm400Gray } from '~/components/typography'
import VText from '~/components/ui/VText.vue'

import eventsMixin from '~/mixins/events'

import { useMiscResizeStore } from '~/stores/miscResize'

import { Breakpoints } from '~/enums/breakpoints'

import type BlogPost from '~/types/blogPost'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    RatioContainer,
    Sm400Gray,
  },
  mixins: [eventsMixin],
  props: {
    post: {
      type: Object as PropType<BlogPost>,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(useMiscResizeStore, ['SCREEN_WIDTH']),
    titleConfig() {
      return this.SCREEN_WIDTH >= Breakpoints.xs375
        ? 'sans/20/bold'
        : 'sans/16/medium'
    },
  },
  methods: {
    handlePostClick() {
      this.trackEvent({
        category: 'External Visit',
        action: 'Blog Post',
        title: this.post.title,
        link: this.post.link,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
