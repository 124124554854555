<script setup lang="ts">
import { storeToRefs } from 'pinia'

import HomepageBlogCard from './blog/card.vue'

import {
  Base400Black,
  Base500Orange,
  Base600Black,
  Sm500Orange,
} from '~/components/typography/'
import VText from '~/components/ui/VText.vue'

import { useMiscResizeStore } from '~/stores/miscResize'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { Breakpoints } from '~/enums/breakpoints'

import type BlogPost from '~/types/blogPost'

type Props = {
  posts: BlogPost[]
  hasAdditionalText?: boolean
  hasAdditionalCard?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hasAdditionalText: true,
})

const route = useRoute()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

const blogLink = 'https://blog.groover.co/' as const
const numberOfPosts = 3 as const

const displayedPosts = computed(() => props.posts.slice(0, numberOfPosts))
const linkCardComponent = computed(() =>
  SCREEN_WIDTH.value >= 480 ? Base500Orange : Sm500Orange,
)

function handleMetaVisitClick(): void {
  trackEvent(
    {
      category: 'External Visit',
      action: 'Blog Page',
      link: blogLink,
      origin: 'Homepage',
    },
    route,
  )
}
</script>

<template>
  <div class="tw-relative">
    <a
      :href="blogLink"
      target="_blank"
      rel="noopener noreferrer"
      @click="handleMetaVisitClick"
    >
      <div v-if="hasAdditionalText" class="mainParentContainer tw-mb-0">
        <VText cfg="sans/24/bold">
          {{ $t('band.homepage.blog00') }}
        </VText>
        <i v-if="SCREEN_WIDTH < Breakpoints.lg" class="fas fa-chevron-right" />
      </div>
    </a>
    <div v-if="hasAdditionalText" class="subtitle mainParentContainer">
      <Base400Black>{{ $t('band.homepage.blog01') }}</Base400Black>
      <Base400Black class="tw-hidden 600:tw-inline">{{
        $t('band.homepage.blog03')
      }}</Base400Black>
    </div>
    <div class="noScrollbar tw-relative tw-overflow-x-auto">
      <div class="absoluteWrap leftPadAdjust rightPadAdjust">
        <div
          class="cardArea baseGridGap tw-px-0 tw-py-6 lg:tw-py-10"
          :class="{ noAdditionalCard: !hasAdditionalCard }"
        >
          <HomepageBlogCard
            v-for="post in displayedPosts"
            :key="post.id"
            :post="post"
          />
          <a
            v-if="hasAdditionalCard"
            key="goTo"
            class="tw-relative tw-block tw-w-full tw-rounded tw-bg-white tw-shadow tw-transition-shadow tw-ease-out hover:tw-shadow-hover lg:tw-hidden"
            :href="blogLink"
            target="_blank"
            @click="handleMetaVisitClick"
          >
            <div
              class="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-center"
            >
              <component :is="linkCardComponent" class="preWrap">{{
                $t('band.homepage.blog04')
              }}</component>
            </div>
          </a>
        </div>
      </div>
    </div>
    <a
      v-if="hasAdditionalText"
      class="bottomContent tw-hidden md:tw--mt-4 md:tw-mb-12 md:tw-block lg:tw--mt-5 lg:tw-mb-20"
      :class="{ mainParentContainer: SCREEN_WIDTH >= Breakpoints.md }"
      :href="blogLink"
      target="_blank"
      @click="handleMetaVisitClick"
    >
      <div class="fullText">
        <Base600Black>{{ $t('band.homepage.blog04') }}</Base600Black>
        <i class="fas fa-chevron-right" />
      </div>
    </a>
  </div>
</template>

<style scoped lang="scss">
.absoluteWrap {
  width: fit-content;
}

.cardArea {
  --totalCardCount: 4;

  @apply tw-grid tw-px-0;

  grid-template-columns: repeat(
    var(--totalCardCount),
    calc(var(--grid-width) * 2.5 + (var(--grid-gap) * 2))
  );

  &.noAdditionalCard {
    --totalCardCount: 3;
  }

  @screen sm {
    grid-template-columns: repeat(
      var(--totalCardCount),
      calc((((var(--page-width) - (3 * 16px)) / 4) * 2.5 + (16px * 2)))
    );
  }

  @screen 600 {
    grid-template-columns: repeat(
      var(--totalCardCount),
      calc(var(--grid-width) * 2)
    );
  }

  @screen md {
    grid-template-columns: repeat(
      var(--totalCardCount),
      calc((var(--grid-width) * 3) + (2 * var(--grid-gap)))
    );
  }

  @screen lg {
    grid-template-columns: repeat(3, 1fr);
  }
}

.preWrap {
  max-width: 140px;
}
</style>
