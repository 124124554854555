<script setup lang="ts">
import { storeToRefs } from 'pinia'

import RatioContainer from '~/components/shared/ratioContainer.vue'
import { Sm400Gray } from '~/components/typography'
import VText from '~/components/ui/VText.vue'

import { useMiscResizeStore } from '~/stores/miscResize'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { Breakpoints } from '~/enums/breakpoints'

import type SuccessStory from '~/types/successStory'

type Props = {
  post: SuccessStory
}

const props = defineProps<Props>()

const route = useRoute()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

const titleConfig = computed(() =>
  SCREEN_WIDTH.value >= Breakpoints.xs375 ? 'sans/20/bold' : 'sans/16/medium',
)

function handleEventClick(): void {
  trackEvent(
    {
      category: 'External Visit',
      action: 'Succ Story Card Visit',
      link: props.post.link,
      author: props.post.author,
    },
    route,
  )
}
</script>

<template>
  <div class="tw-relative tw-w-full">
    <div
      class="tw-h-full tw-w-full tw-rounded tw-bg-white tw-shadow tw-transition-shadow tw-duration-100 tw-ease-out hover:tw-shadow-hover"
    >
      <RatioContainer :x-aspect="3" :y-aspect="2">
        <a
          class="tw-relative tw-block tw-h-full tw-w-full"
          :href="post.link"
          target="_blank"
          @click="handleEventClick"
        >
          <img
            class="tw-h-full tw-w-full tw-object-cover tw-object-center"
            :src="post.picture_url"
          />
        </a>
      </RatioContainer>
      <div class="tw-flex tw-w-full tw-flex-col tw-p-5">
        <VText :cfg="titleConfig" color="black" class="clamp tw-mb-2">
          {{ post.author }}
        </VText>
        <Sm400Gray class="clamp">{{ post.body }}.</Sm400Gray>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
