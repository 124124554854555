<script setup lang="ts">
import { storeToRefs } from 'pinia'

import HomepageSuccessStoryCard from './successStory/card.vue'

import {
  Base400Black,
  Base500Orange,
  Base600Black,
  Sm500Orange,
} from '~/components/typography/'
import VText from '~/components/ui/VText.vue'

import { useMiscResizeStore } from '~/stores/miscResize'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { Breakpoints } from '~/enums/breakpoints'

import type SuccessStory from '~/types/successStory'

type Props = {
  stories: SuccessStory[]
  hasAdditionalText?: boolean
  hasAdditionalCard?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hasAdditionalCard: false,
  hasAdditionalText: false,
})

const route = useRoute()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

const MAX_POST_AMOUNT = 3

const displayedPosts = computed(() => props.stories.slice(0, MAX_POST_AMOUNT))

const linkCardComponent = computed(() =>
  SCREEN_WIDTH.value >= Breakpoints.sm
    ? markRaw(Base500Orange)
    : markRaw(Sm500Orange),
)

function handleEventClick(): void {
  trackEvent(
    {
      category: 'External Visit',
      action: 'Succ Story Visit',
      origin: 'Homepage',
    },
    route,
  )
}
</script>

<template>
  <div class="tw-relative tw-w-full">
    <a
      href="https://blog.groover.co/category/success-stories/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div v-if="hasAdditionalText" class="mainParentContainer tw-mb-0">
        <VText cfg="sans/24/bold">
          {{
            SCREEN_WIDTH >= 375
              ? $t('band.homepage.successStory00')
              : $t('band.homepage.successStory04')
          }}
        </VText>
        <i class="fas fa-chevron-right tw-inline md:tw-hidden" />
      </div>
    </a>
    <div v-if="hasAdditionalText" class="subtitle mainParentContainer">
      <Base400Black class="tw-hidden sm:tw-inline">{{
        $t('band.homepage.successStory01')
      }}</Base400Black>
      <Base400Black class="tw-inline sm:tw-hidden">{{
        $t('band.homepage.successStory03')
      }}</Base400Black>
    </div>
    <div class="noScrollbar tw-relative tw-overflow-x-auto">
      <div class="absoluteWrap leftPadAdjust rightPadAdjust">
        <div
          class="cardArea baseGridGap tw-px-0 tw-py-6 lg:tw-py-10"
          :class="{ noAdditionalCard: !hasAdditionalCard }"
        >
          <HomepageSuccessStoryCard
            v-for="post in displayedPosts"
            :key="post.id"
            :post="post"
          />
          <a
            v-if="hasAdditionalCard"
            key="goTo"
            class="tw-relative tw-block tw-w-full tw-rounded tw-bg-white tw-shadow tw-transition-shadow tw-ease-out hover:tw-shadow-hover lg:tw-hidden"
            href="https://blog.groover.co/category/success-stories/"
            target="_blank"
          >
            <div
              class="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-center"
            >
              <component :is="linkCardComponent" class="preWrap">{{
                $t('band.homepage.successStory02')
              }}</component>
            </div>
          </a>
        </div>
      </div>
    </div>
    <a
      v-if="hasAdditionalText"
      class="bottomContent mainParentContainer tw-hidden md:tw-block"
      href="https://blog.groover.co/category/success-stories/"
      target="_blank"
      @click="handleEventClick"
    >
      <div class="fullText">
        <Base600Black>{{ $t('band.homepage.successStory02') }}</Base600Black>
        <i class="fas fa-chevron-right" />
      </div>
    </a>
  </div>
</template>

<style scoped lang="scss">
.absoluteWrap {
  width: fit-content;
}

.cardArea {
  --totalCardCount: 4;
  @apply tw-grid tw-px-0;

  grid-template-columns: repeat(
    var(--totalCardCount),
    calc(var(--grid-width) * 2.5 + (var(--grid-gap) * 2))
  );

  &.noAdditionalCard {
    --totalCardCount: 3;
  }

  @screen sm {
    grid-template-columns: repeat(
      var(--totalCardCount),
      calc((((var(--page-width) - (3 * 16px)) / 4) * 2.5 + (16px * 2)))
    );
  }

  @screen 600 {
    grid-template-columns: repeat(
      var(--totalCardCount),
      calc(var(--grid-width) * 2)
    );
  }

  @screen md {
    grid-template-columns: repeat(
      var(--totalCardCount),
      calc((var(--grid-width) * 3) + (2 * var(--grid-gap)))
    );
  }

  @screen lg {
    grid-template-columns: repeat(3, 1fr);
  }
}

.preWrap {
  max-width: 140px;
}
</style>
